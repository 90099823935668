<template>
  <select v-if="options" class="form-select form-select-sm form-select-solid" :name="name" :lang="lang" :data-tags="tags" :data-placeholder="placeholder" :data-allow-clear="allowClear" :multiple="multiple" :data-hide-search="hideSearch" :data-dropdown-parent="dropDownParent">
        <option v-if="!hidePlaceholder"></option>
        <option v-for="option in options" :value="option.value" :key="option.value" :selected="option.value == selected">{{ option.text }}</option>
  </select>
</template>

<script>
export default {
    name: 'Select2',
    props: {
        name: String,
        lang: String,
        isSolid: Boolean,
        size: String,
        change: null,
        placeholder: String,
        hideSearch: Boolean,
        allowClear: Boolean,
        dropDownParent: String,
        tags: Boolean,
        options: null,
        multiple: Boolean,
        selected: null,
        hidePlaceholder: Boolean
    },
    methods: {
        getClass() {
            let cls = 'form-select';

            if (this.isSolid) cls += ' form-select-solid'
            if (this.size == 'sm') cls += ' form-select-sm'

            return cls;
        }
    },
    mounted() {
        this.$el.className = this.getClass();
        const options = {};
        if (this.hideSearch) options.minimumResultsForSearch = Infinity
        $(this.$el).select2(options);
        if (this.change) $(this.$el).on('change', this.change);
    }
}
</script>

<style>

</style>